import * as React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"

const NotFoundPage = () => {
  return (
    <>
        <title>Cyzer Studio LLC | 404 Not Found</title>
        <Layout>
            <div className="notfound">
                <section className="notfound__content">
                    <div className="notfound__text-container">
                        <h1 className="notfound__header">Oops!</h1>
                        <p className="notfound__leading">
                            Sorry{" "}
                            <span role="img" aria-label="Pensive emoji">
                            😔
                            </span>{" "}
                            we couldn’t find the page you were looking for.
                            <br />
                            <br />
                            <Link className="notfound__link" to="/">Back to home</Link>
                        </p>
                    </div>
                </section>
            </div>
        </Layout>
    </>
  )
}

export default NotFoundPage
